@import '../theme.scss';

.pf {
	padding-top:300px;
	.tab {
		position:relative;
		margin-bottom:30px;
		ul {
			display:flex;
			li {
				border-bottom:3px solid transparent;
				& ~ li {
					margin-left:20px;
				}
				button {
					display:block;
					font-size:26px;
					font-weight:700;
					color:#fff;
					opacity:.4;
				}
				&.active {
					border-color:$primary;
					button {
						opacity:1;
					}
				}
			}
		}
	}
}

.cards {
	display:flex;
	flex-wrap:wrap;
	gap:30px 20px;

	& .card {
		width:calc(33.33% - 14px);

		&_inner {
			position:relative;
			height:270px;
			border-radius:15px;
			box-shadow:0 5px 8px 1px rgba($color:#000000, $alpha:.2);
			overflow:hidden;
			transition:.5s;
			transition-delay:.2s;

			img {
				display:block;
				width:100%;
				height:100%;
				object-fit:cover;
				transition:.4s;
			}
			a::after {
				content:'MORE';
				width:100%;
				height:100%;
				display:flex;
				align-items:center;
				justify-content:center;
				position:absolute;
				left:0; 
				top:0;
				background-color:rgba($color:#000000, $alpha:.5);
				transition:.4s;
				opacity:0;
				color:#fff;
				font-size:30px;
				font-weight:700;
			}
			.over {
				position:absolute;
				overflow:hidden;
				display:flex;
				align-items:center;
				justify-content:center;
				background:rgba(0, 0, 0, .6);
				color:#fff;
				font-size:25px;
				text-decoration:underline;
				text-underline-offset:10px;
			}
		}
		&:hover{
			a::after {
				opacity:1;
			}
			img{
				transform:scale(1.05);
			}
		}
		&_img {
			width:100%;
			height:100%;
			transition:.5s;
		}
		h3 {
			color:#fff;
			margin-top:5px;
			font-size:20px;
			font-weight:700;
		}
		.tag {
			display:flex;
			flex-wrap:wrap;
			gap:5px;
			margin-top:10px;
			li{
				padding:4px 5px;
				background-color:transparent;
				border-radius:5px;
				color:#fff;
				font-size:13px;
				font-weight:600;
				&:nth-child(1) {
					background-color:#d64848;
				}
				&:nth-child(2) {
					background-color:#ca6c6c;
				}
				&:nth-child(3) {
					background-color:#c98a8a;
				}
			}
		}
	}
}
@media only screen and (max-width:1200px) {
	.pf {
		padding-top:150px;
	}
	.cards {
		gap:30px;

		& .card {
			width:calc(50% - 15px);
			&_inner {
				height:240px;
			}
			h3 {
				font-size:18px;
			}
			.tag {
				li {
					font-size:12px;
					font-weight:500;
				}
			}
		}
	}
}
@media only screen and (max-width:768px) {
	.pf {
		.tab {
			ul {
				li {
					button {
						font-size:20px;
					}
				}
			}
		}
	}
	.cards {
		gap:30px;

		& .card {
			width:100%;
			max-width:370px;
			&:nth-child(2n) {
				margin-left:auto;
			}
			.over {
				display:none;
			}
		}
	}
}