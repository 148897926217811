@import '../theme.scss';

.book {
	padding-top:200px;
	.pages {
		max-width:1200px;
		width:100%;
		height:600px;
		margin:0 auto;
		position:relative;
		&::after {
			display:block;
			content:'';
			width:100%;
			height:115px;
			background:url(../../../public/img/sh.png) no-repeat;
			background-size:100% auto;
			background-position:center top -35px;
			position:absolute;
			left:0;
			bottom:-80px;
			z-index:0;
		}
		.book_area {
			perspective:2000px;
			position:absolute;
			z-index:1;
			right:10%;
			transform:none;
			top:0;
			width:40%;
			transition:1s;
			&.first_cover {
				right:50%;
				transform:translateX(50%);
			}
			&.last_cover {
				transform:translateX(50%);
			}
		}
		.page_wrap {
			position:absolute;
			right:0;
			top:0;
			width:100%;
			height:600px;
			transform-style:preserve-3d;
			transform-origin:0 50%;
			transition:transform 1s ease-in-out, z-index 0.1s 0.6s ease-in-out;
			box-shadow:0 25px 30px -20px rgb(58 49 49);
			border-radius:10px;
		}

		&_page {
			position:absolute;
			width:100%;
			height:100%;
			border:1px solid #5c5c5c;
			backface-visibility:hidden;
			background-color:#fff;
			border-radius:10px;
			&.back {
				transform:rotateY(-180deg);
			}
			&__inner {
				height:100%;
				box-sizing:border-box;
				padding:90px 90px 30px;
				position:relative;
				overflow:hidden;
				border-radius:10px;
			}
			.control_btn {
				position:absolute;
				top:50%;

				transform:translateY(-50%);

				button {
					display:block;
					background-repeat:no-repeat;
					background-size:40px;
					background-position:center;
					width:60px;
					height:60px;
					border:2px solid #b0b0b0;
					border-radius:50%;
					text-indent:-9999px;
				}
				&.prev {
					left:20px;
					button {
						background-image:url(../../../public/img/arr_l_2.svg);
					}
				}
				&.next {
					right:20px;
					button {
						background-image:url(../../../public/img/arr_r_2.svg);
					}
				}
			}
			.book_home {
				width:30px;
				height:30px;
				display:block;
				background:url(../../../public/img/ico_home.png) no-repeat 0 0;
				background-size:100% auto;
				position:absolute;
				right:35px;
				top:35px;
			}
			.comp_info {
				padding-top:200px;
				p {
					color:#fff;
					font-size:20px;
				}
			}
			h4 {
				font-weight:700;
				font-size:20px;
				margin-bottom:15px;
			}
			.work_txt {
				margin-bottom:50px;
				li {
					line-height:1.4;
					padding-left:15px;
					position:relative;
					font-size:17px;
					&::after {
						display:block;
						content:'';
						width:5px;
						height:5px;
						background-color:$primary;
						border-radius:50%;
						position:absolute;
						left:0; top:7px;
					}
				}
			}
			.skill_list {
				ul {
					display:flex;
					flex-wrap:wrap;
					gap:20px;

					li {
						figure {
							width:55px;
							height:55px;
						}
						p {
							text-align:center;
							font-size:16px;
							font-weight:700;
							margin-top:10px;
						}
					}
				}
			}

			&.page_01, &.page_03, &.page_05 {
				.pages_page__inner {
					background-image:-webkit-linear-gradient(right, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.15) 100%);
				}
			}
			&.page_02 , &.page_04 ,&.page_06 {
				.pages_page__inner {
					background-image:-webkit-linear-gradient(left, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.2) 100%);
				}
			}

			&.page_01 {
				background-color:#6d695c;
				background-image:
				repeating-linear-gradient(120deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
				repeating-linear-gradient(60deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
				linear-gradient(60deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1)),
				linear-gradient(120deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1));
				background-size:70px 120px;
				.pages_page__inner {
					padding:60px;
				}
				h3 {
					color:#fff;
					font-size:65px;
					text-align:center;
					font-family:"Poppins", sans-serif;
				}
				.start_book {
					margin:120px auto 0;
					display:flex;
					align-items:center;
					position:relative;
					height:60px;
					&::before {
						content:'';
						position:absolute;
						left:-20px;
						top:50%;
						transform:translateY(-50%);
						display:block;
						border-radius:32px;
						background-color:$primary;
						width:60px;
						height:60px;
						transition:.3s;
						opacity:0.5;
						animation:circle 2s ease-in-out alternate infinite;
					}
					&:hover{
						&::before {
							width:calc(100% + 30px);
							opacity:0.7;
							animation:none;
						}
					}
					span {
						color:#fff;
						font-weight:700;
						font-size:30px;
						z-index:1;
						height:60px;
						line-height:58px;
					}
					svg {
						position:relative;
						top:0;
						margin-left:10px;
						fill:none;
						stroke-linecap:round;
						stroke-linejoin:round;
						stroke:#fff;
						stroke-width:2;
						transform:translateX(-5px);
						transition:all .3s ease;
						z-index:1;
					}
				}
			}

			&.page_02 {
				background:#313131 url(../../../public/img/bg_work.png) no-repeat center;
				.chess_01 {
					display:block;
					background:url(../../../public/img/chess_01.png) no-repeat;
					background-size:100%;
					width:300px;
					height:300px;
					opacity:.5;
					transform:rotate(-15deg);
					position:absolute;
					right:-100px;
					bottom:-70px;
					z-index:-1;
				}
				.logo_w {
					color:#fff;
					font-weight:700;
					font-size:75px;
					position:relative;
					&::before {
						display:block;
						content:'';
						background:url(../../../public/img/wylie.png) no-repeat 0 0;
						background-size:100% auto;
						width:150px;
						height:78px;
						position:absolute;
						left:-30px;
						top:-10px;
					}
					span {
						font-size:20px;
						font-weight:500;
					}
				}
			}

			&.page_04 {
				background:url(../../../public/img/bg_book.jpg) no-repeat center;
				.logo_w {
					color:#fff;
					font-weight:700;
					font-size:55px;
					position:relative;
					line-height:1em;
					&::before {
						display:block;
						content:'';
						background:url(../../../public/img/future.png) no-repeat 0 0;
						background-size:100% auto;
						width:150px;
						height:78px;
						position:absolute;
						left:-30px;
						top:-20px;
						opacity:0.5;
						padding-top:10px;
					}
					span {
						display:block;
						font-size:20px;
						font-weight:500;
					}
				}
			}

			&.page_03, &.page_05 {
				background-color:#fff;
				background-image:
				linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px),
				linear-gradient(#eee .1em, transparent .1em);
				background-size:100% 1.2em;
			}

			&.page_06{

				background-color:#6d695c;
				background-image:
				repeating-linear-gradient(120deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
				repeating-linear-gradient(60deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
				linear-gradient(60deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1)),
				linear-gradient(120deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1));
				background-size:70px 120px;
				.pages_page__inner {
					padding-bottom:90px;
				}
				.back_txt {
					display:flex;
					align-items:center; justify-content:center;
					flex-direction:column;
					height:100%;
					.mark {
						font-size:140px;
						color:#fff;
						display:block;
						border:3px solid #fff;
						border-radius:50%;
						width:160px;
						height:160px;
						line-height:1.2;
						text-align:center;
					}
					p {
						color:#fff;
						text-align:center;
						font-size:40px;
						line-height:1;
						margin-top:70px;
						font-weight:700;
					}
				}
			}
		}
		.cnt1 {
			z-index:5;
		}
		.cnt2 {
			z-index:4;
		}
		.cnt3 {
			z-index:3;
		}
	}

	// 책장 넘기기 효과
	.turn {
		transform:rotateY(-180deg);
		&.cnt1{
			z-index:3;
		}
		&.cnt2 {
			z-index:4;
		}
		&.cnt3 {
			z-index:5;
		}
	}
}
@media only screen and (min-width:1241px) {
	.first_next {
		animation:arrow 0.7s ease-in-out alternate infinite;
	}
}
@media only screen and (max-width:1240px) {
	.book {
		.pages {
			&::after {
				background-position:center bottom 30px;
			}
			.book_area {
				width:50%;
				right:0;
			}
			&_page{

				&__inner {
					padding:80px 32px;
				}

				.skill_list {
					ul {
						gap:15px;

						li {
							figure {
								width:50px;
								height:50px;
							}
							p {
								font-size:15px;
							}
						}
					}
				}
				&.page_01 {
					.pages_page__inner {
						padding:50px 30px;
					}
					h3 {
						font-size:50px;
					}
					.start_book {
						margin:150px auto 0;
						height:60px;
					}
				}
				&.page_02 {
					.logo_w {
						font-size:60px;
					}
				}
				&.page_04 {
					.logo_w {
						font-size:40px;
					}
				}
				&.page_02, &.page_04 {
					.pages_page__inner {
						padding:80px 50px;
					}
				}
				&.page_06{
					.pages_page__inner {
						padding-bottom:80px;
					}
				}
				&.page_03, &.page_05 {
					background-position:left -50px top;
				}
			}
		}
	}
}
@media only screen and (max-width:768px) {
	.book {
		.pages {
			transition:1s;
			height:400px;
			&.open {
				height:800px;
				.book_area {
					top:400px;
				}
			}
			&::after {
				display:none;
			}
			.book_area {
				width:100%;
				.page_wrap {
					transform-origin:50% 0;
					height:400px;
				}
				&.last_cover {
					transform:none;
					top:400px;
				}
			}
			&_page {
				&.back {
					transform:rotateX(180deg);
				}
				.skill_list {
					ul {
						gap:10px;

						li {
							figure {
								width:40px;
								height:40px;
							}
							p {
								font-size:14px;
							}
						}
					}
				}
				h4 {
					font-size:16px;
					margin-bottom:10px;
				}
				.control_btn {
					position:absolute;
					top:20px;
					transform:translateX(-50%) rotate(90deg);
					button {
						width:40px;
						height:40px;
						background-size:30px;
					}
					&.prev {
						left:50%;
					}
					&.next {
						right:50%;
						top:inherit;
						bottom:10px;
						transform:translateX(50%) rotate(90deg);
					}
				}
				.book_home {
					right:20px;
					top:20px;
					width: 25px;
					height: 25px;
				}
				.work_txt {
					margin-bottom:30px;
					li {
						font-size:14px;
						line-height:1.3;
						padding-left:10px;
						&::after {
							top:5px;
						}
					}
				}
				&__inner {
					padding:40px 20px 20px 35px;
				}
				.comp_info {
					padding-top:50px;
				}
				&.page_01 {
					.pages_page__inner {
						padding:50px 20px;
					}
					h3 {
						font-size:40px;
					}
					.start_book {
						margin:50px auto 0;
						span {
							font-size:25px;
						}
					}
				}
				&.page_02, &.page_04 {
					.pages_page__inner {
						padding:100px 40px 20px;
					}
				}
				&.page_06{
					.back_txt {
						.mark {
							font-size:100px;
							width:120px;
							height:120px;
						}
						p {
							margin-top:30px;
						}
					}
				}
				&.page_06{
					.pages_page__inner {
						padding-bottom:20px;
					}
				}
			}
		}
		.turn {
			transform:rotateX(180deg) rotateY(0);
		}
	}
}


@keyframes circle {
	0% {
		opacity:0.5;
	}
	100% {
		opacity:0.3;
	}
}
@keyframes arrow {
	0% {
		right:20px;
	}
	100% {
		right:7px;
	}
}