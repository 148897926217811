/* ============================== [ 공통레이아웃 ] ========================================================================================== */

/* -------------------- #contents ---------------------------------------- */
body, html {
	background:url(../../public/img/bg_main.jpg);
	font-size:10px;
}
.inner {
	max-width:1200px;
	width:100%;
	margin:0 auto;
	padding:0 20px;
	box-sizing:border-box;
}

/* -------------------- Header ---------------------------------------- */
header {
	padding:10px 0 0 0;
	position:fixed;
	left:20px;
	top:0;
	width:calc(100% - 40px);
	z-index:100;
	&.is_scroll {
		.inner {
			border:3px solid #fff;
			background:#000;
			.name {
				color:#fff;
			}
		}
	}
	.inner {
		max-width:1160px;
		display:flex;
		justify-content:space-between;
		padding:10px;
		border:3px solid #000;
		background:#fff;
		transition:.4s;
		.name {
			position:relative;
			display:block;
			color:#000;
			font-weight:600;
			transition:.4s;
			&::before{
				display:block;
				content:'';
				width:30px;
				height:8px;
				position:absolute;
				left:-18px; 
				top:2px;
				background-color:$primary;
				transform:rotate(-45deg);
			}
			&::after{
				display:block;
				content:'';
				width:30px;
				height:8px;
				position:absolute;
				right:-16px; 
				bottom:2px;
				background-color:$primary;
				transform:rotate(-45deg);
			}
		}
	}

	.theme {
		display:flex;
		align-items:center;
		&_text {
			font-weight:700;
			&_mask {
				display:inline-flex;
				flex-direction:column;
				height:20px;
				overflow:hidden;
				span {
					position:relative;
					transition:transform .7s cubic-bezier(.215,.61,.355,1),
				}
			}
		}
		&_icon {
			position:relative;
			display:inline-block;
			width:28px;
			height:28px;
			margin-left:20px;
			&_sun {
				display:none;
				position:relative;
				width:24px;
				height:24px;
				transition:transform .7s ease;
				span {
					display:block;
					position:absolute;
					left:50%;
					top:50%;
					width:20px;
					height:20px;
					background-color:#000;
					border-radius:50%;
					transform:translate(-50%, -50%);
					z-index:1;
				}
				&::before {
					display:block;
					content:'';
					width:24px;
					height:24px;
					position:absolute;
					left:0;
					top:0;
					background-color:#fff;
				}
				&::after {
					display:block;
					content:'';
					width:24px;
					height:24px;
					position:absolute;
					left:0;
					top:0;
					background-color:#fff;
					transform:rotate(45deg);
				}
			}
			&_moon {
				display:block;
				width:100%;
				height:100%;
				background-color:$white;
				border:2px solid $black;
				border-radius:50%;
				box-sizing:border-box;
				overflow:hidden;

				&::before {
					display:block;
					content:'';
					width:90%;
					height:90%;
					background-color:$black;
					border-radius:50%;
					transform:translate(35%,-15%);
					transition:transform 1.3s ease;
				}
			}
		}
		&:hover {
			.theme_icon_moon::before {
				transform:translate(-35%,-15%)
			}
			.theme_icon_sun {
				transform:rotate(180deg);
			}
		}
	}
}
.util_btn {
	position:fixed;
	right:30px;
	bottom:30px;
	display:flex;
	flex-direction:column;
	align-items:flex-end;
	z-index:10;
}
.go_top {
	width:50px;
	height:50px;
	margin-top:20px;
	border-radius:50%;
	border:1px solid $primary;
	background:$primary url(../../public/img/ico_arrow_up.png) no-repeat center;
	box-shadow:0 4px 8px rgba(0, 0, 0, 0.28);
}

.file_down {
	position:relative;
	.down_input {
		position:absolute;
		width:0;
		height:0;
		padding:0;
		border:0;
		overflow:hidden;
	}
	.box {
		position:relative;
		display:flex;
		align-items:center;
		padding:15px;
		background-color:#fff;
		box-shadow:0px 3px 8px rgba(0, 0, 0, 0.25);
		border-radius:24px;
		transform:translateX(0);
		transition:all .7s cubic-bezier(0.82, -0.02, 0.4, 1.18);
		z-index:1;
		.text {
			margin-left:10px;
			p {
				font-weight:700;
				font-size:16px;
				line-height:1em;
			}
			span {
				font-weight:500;
				font-size:14px;
				line-height:1em;
			}
		}
		.dots {
			margin-left:20px;
			div {
				width:6px;
				height:6px;
				margin:3px;
				background:#e2574c;
				border-radius:100%;
				transition:all .7s cubic-bezier(0.82, -0.02, 0.4, 1.18);
			}
		}

		&:hover{
				transform:translateX(-80px);
			.dots {
				div:nth-child(1) {
					transform:translateY(9px);
				}
				div:nth-child(2) {
					transform:scale(2.5);
				}
				div:nth-child(3) {
					transform:translateY(-9px);
				}
			}
		}
	}
	.pdf_box {
		display:block;
		width:85px;
		height:69px;
		position:absolute;
		right:0;
		top:0;
		background:#fff url(../../public/img/ico_pdf.png) no-repeat center;
		background-size:30px auto;
		border-radius:0 24px 24px 0;
		box-shadow:0px 3px 8px rgba(0, 0, 0, 0.25);
		transition:all .7s cubic-bezier(0.82, -0.02, 0.4, 1.18);
		transform:translateX(-60px) scale(.7);
		opacity:0;
		z-index:-1;
	}
	label{
		cursor:pointer;
		&:hover{
			.box{
				transform:translateX(-68px);

				.dots {
					div:nth-child(1) {
						transform:translateY(9px);
					}
					div:nth-child(2) {
						transform:scale(2.5);
					}
					div:nth-child(3) {
						transform:translateY(-9px);
					}
				}
			}
			.pdf_box {
				opacity:1;
				transform:translateX(0) scale(1);
			}
		}
	}
}

/* -------------------- Footer ---------------------------------------- */
footer {
	padding-bottom:60px;
	.inner {
		display:flex;
		align-items:center;
		justify-content:space-between;

		.name {
			color:#fff;
			font-weight:500;
		}
		.bar {
			margin-top:3px;
			width:75%;
			height:4px;
			background:linear-gradient(to right, rgba(243, 134, 134, 0.1) 0%, $primary 100%);
		}
		.copy {
			color:#fff;
			font-weight:500;
			font-size:16px;
		}
	}
}

/* -------------------- section Title ---------------------------------------- */
.tit_wrap {
	margin-bottom:50px;
	h2 {
		position:relative;
		font-weight:700;
		font-size:70px;
		color:#fff;
		letter-spacing:-1px;
		z-index:1;

		&::after {
			display:block;
			content:'';
			width:100%;
			height:18px;
			position:absolute;
			left:0;
			bottom:12px;
			background-color:#fff;
			opacity:.5;
			z-index:-1;
		}
	}
	p {
		color:$primary;
		font-weight:500;
		font-size:20px;
	}
}

@media only screen and (max-width:1200px) {
	.tit_wrap {
		h2 {
			font-size:60px;
			&::after {
				height:10px;
				bottom:6px;
			}
		}
	}
}
@media only screen and (max-width:768px) {
	.tit_wrap {
		h2 {
			font-size:40px;
		}
	}
	footer .inner {
		flex-direction:column;
		align-items:flex-start;
		gap:10px;

		.bar {
			width:100%;
		}
	}
	.file_down {
		.box {
			border-radius:12px;
			.text {
				display:none;
			}
			.dots {
				display:none;
			}
		}
		.pdf_box {
			width:49px; 
			height:49px;
			border-radius:12px;
			transform:translateX(-20px);
		}
		label{
			&:hover{
				.box{
					transform:translateX(-38px);
				}
			}
		}
	}

}

.loading {
	width:100%;
	height:100vh;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	background-color:#fff;
	p {
		font-weight:600;
		font-size:20px;
	}
}

#wrap {
	position:relative;
}
.content {
	overflow:hidden;
}