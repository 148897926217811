@import '../theme.scss';

.container {
	position:relative;
	background-color:#f5f5f5;
	&_wrap {
		height:200vh;
		position:sticky;
		top:0;
	}
	&_inner {
		position:sticky;
		top:0;
		height:100vh;
		.content_body {
			position:relative;
			height:100vh;
			z-index:2;
			.body_inner {
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%, -50%);
				width:100%;
				height:100vh;
			}
		}
		.content_bg {
			box-shadow:0px 20px 19px 10px rgb(93 93 93);
			background-color:#111;
			background-size:40px 40px;
			border-radius:40px 40px 0 0;
			width:90%;
			height:100vh;
			position:absolute;
			left:50%;
			bottom:-20%;
			transform:translateX(-50%);
			transition:.4s;
			z-index:1;

			.main_txt {
				color:#fff;
				position:absolute;
				left:10%;
				top:15%;
				font-size:100px;
				font-weight:700;
				transition:.4s;
				opacity:1;
			}
		}
	}
}

.scrolled {
	.content_bg {
		width:100%;
		bottom:0;
		border-radius:0 !important;
		box-shadow:none;
		background-image:repeating-radial-gradient( white 0px, black 2px, black 48px);
		.main_txt {
			opacity:0;
		}
	}
	.visual {
		.my_card {
			transform:translate(0, 0) rotateY(180deg);
		}
	}
		
}

.visual {
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
	perspective:500px;
	overflow:hidden;

	.my_card {
		position:relative;
		transform-style:preserve-3d;
		transform-origin:50%;
		transform:translate(70%, 40%) rotate(0);
		transition:.6s;
		width:500px;
		height:600px;
		z-index:1;

		.card_print {
			position:absolute;
			width:100%;
			height:100%;
			backface-visibility:hidden;
			img {
				border-radius:15px;
			}
		}
		.card_b {
			transform:rotateY(-180deg);
		}
	}

	.card {
		box-sizing:border-box;
		box-shadow:0 0 0 8px rgba(255, 255, 255, 0.3);
		border-radius:15px;
		background-color:rgba(255, 255, 255, 1);
		position:relative;
		display:flex;
		flex-direction:column;
		height:100%;
		overflow:hidden;
		transition:0.6s;
		z-index:1;

		& .header {
			width:100%;
			height:220px;
			padding:0 20px;
			position:relative;
			box-sizing:border-box;
			display:flex;
			flex-direction:column;
			justify-content:center;
			align-items:center;
			transition:0.6s;

			* {
				transition:0.6s;
			}
			.avatar {
				width:110px;
				height:110px;
				border-radius:50%;
				position:absolute;
				left:50%;
				top:20px;
				transform:translateX(-50%);
				overflow:hidden;
				img {
					width: auto;
					height: 100%;
					object-fit:cover;
					margin: 0 auto;
				}
			}
			p {
				padding-top:130px;
				font-size:25px;
				font-weight:700;
			}
			span {
				margin-top:5px;
				font-size:18px;
				font-weight:500;
				text-align:center;
				display: block;
			}
		}
		& .content {
			margin-top:40px;
		}
		h2 {
			margin-bottom:15px;
			font-weight:700;
			font-size:20px;
		}
		& .about_desc {
			margin-bottom:20px;
			line-height:1.6em;
		}
		& .social {
			display:flex;
			a {
				width:32px;
				height:32px;
				border-radius:50%;
				background-color:rgba(93, 133, 193, 0.05);
				box-shadow:0 4px 8px rgba(0, 0, 0, 0.2);
				display:flex;
				align-items:center;
				justify-content:center;
				img {
					width:100%;
				}
				& ~ a {
					margin-left:15px;
				}
			}
		}
		& .btn {
			background-color:#fff;
			display:flex;
			margin-top:auto;
			position:sticky;
			bottom:0;
			left:0;

			button {
				flex:1 1 auto;
				padding:20px 5px;
				background:0;
				border:0;
				border-bottom:3px solid transparent;
				font-size:16px;
				font-weight:600;
				transition:.3s;
				outline:0;
				user-select:none;
				cursor:pointer;

				&.isActive,
				&:hover {
					color:#000;
					border-bottom:3px solid rgba(216, 16, 16, 0.6);
					background:linear-gradient(
						to bottom,
						rgba(136, 12, 12, 0) 0%,
						rgba(216, 16, 16, 0.3) 80%,
						rgba(216, 16, 16, 0.4) 100%
					);
				}
			}
		}
		& .timeline {
			margin:30px 0;
			position:relative;
			&:after {
				background:linear-gradient(
					to top,
					rgba(189, 31, 31, 0.1) 0%,
					#d81010 100%
				);
				content:"";
				position:absolute;
				left:42px;
				top:0;
				width:2px;
				height:100%;
				content:"";
			}

			.item {
				position:relative;
				padding:0 10px 30px 60px;
				z-index:1;
				&:last-child {
					padding-bottom:5px;
				}

				&:after {
					content:attr(data-year);
					position:absolute;
					top:0;
					left:37px;
					width:8px;
					height:8px;
					line-height:0.6;
					font-size:13px;
					text-indent:-35px;
					color:rgba(#868686, 0.7);
					border:2px solid #fff;
					border-radius:50%;
					background:linear-gradient(
						to bottom,
						lighten($primary, 20%) 0%,
						$primary 100%
					);
				}
				h3 {
					margin-bottom:5px;
					font-weight:500;
					font-size:19px;
				}
				p {
					font-size:16px;
					line-height:1.5;
				}
			}
		}
	}
	& .contact_wrapper {
		margin:20px 0 30px;
	}
	& .contact {
		display:flex;
		align-items:center;
		& ~ .contact {
			margin-top: 16px;
		}
		i{
			display:flex;
			width:30px;
			padding-right:12px;
			border-right:1px solid #000;
			box-sizing:border-box;
		}
		p{
			flex:1;
			padding-left:12px;
			font-size:15px;
			line-height:1.6;
		}

	}
	.me {
		display:block;
		border:0;
		background:linear-gradient(to right,rgba(247, 183, 183, 0.8) 0%,rgb(216,16,16,.8) 55%);
		box-shadow:0 4px 6px rgba(0, 0, 0, 0.15);
		box-sizing:border-box;
		border-radius:5px;
		padding:12px 16px;
		width:100%;
		margin-top:25px;
		font-size:16px;
		font-weight:500;
		color:#fff;
		text-align:center;
		transition:0.6s;
		cursor:pointer;
		outline:none;
	}
}
.main {
	> div {
		display:none;
		padding:0 20px;
		transition:.6s;
		&.isActive {
			display:block;
			animation:fadeIn 0.6s both;
		}
	}
}
#about .my_card {
	height:600px;
}
#experience .my_card {
	height:450px;
}
#contact .my_card {
	height:420px;
}

#contact .card, #experience .card {
	& .header {
		height:100px;
		align-items:flex-start;

		.avatar {
			width:80px; 
			height:80px;
			left:20px;
			transform:none;
		}
		.txt_wrap {
			margin-left:100px;
			p {
				padding-top:16px;
				font-size:22px;
				text-align:left;
			}
			span {
				font-size:15px;
				text-align:left;
			}
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity:0;
		transform:translateY(40px);
	}
	100% {
		opacity:1;
	}
}

.down {
	position:absolute;
	left:50%;
	bottom:20px;
	transform:translateX(-50%);
	opacity:.8;
	p{
		margin-bottom:5px;
		font-size:15px;
		font-weight:600;
		color:#fff;
	}

	i{
		margin:0 auto;
		position:relative;
		display:block;
		width:16px;
		height:28px;
		border-radius:20px;
		border:2px solid #fff;
		&::after {
			display:block;
			content:'';
			width:2px;
			height:10px;
			position:absolute;
			left:calc(50% - 1px);
			top:3px;
			background-color:#fff;
			transform:translateY(0);
			animation:scrollDown 2s ease-in-out alternate infinite;
		}
	}
}

@keyframes scrollDown {
	0% {
		transform:translateY(0);
	}
	100% {
		transform:translateY(6px);
	}
}
@media only screen and (max-width:1400px) {
	.container {
		&_inner {
			.content_bg{
				.main_txt {
					font-size:80px;
				}
			}
			
		}
	}
	.visual{
		.my_card {
			width:450px;
			transform:translate(64%, 40%) rotate(0);
		}
	}
}
@media only screen and (max-width:1200px) {
	#about .my_card {
		height:500px;
	}
	#experience .my_card {
		height:380px;
	}
	#contact .my_card {
		height:410px;
	}
	.container {
		&_inner {
			.content_bg{
				.main_txt {
					font-size:60px;
					left:50%;
					top:10%;
					transform:translate(-50%);
				}
			}
			
		}
	}
	.visual{
		.my_card {
			width:450px;
			transform:translate(0%, 70%) rotate(0);
		}
	}
	.visual .card {
		& .header {
			height:180px;
			.avatar {
				width:80px;
				height:80px;
			}
			p {
				font-size:20px;
				padding-top:100px;
			}
			span {
				font-size:14px;
			}
		}
		& .content {
			margin-top:20px;
		}
		h2 {
			margin-bottom:10px;
			font-size:16px;
		}
		& .about_desc {
			margin-bottom:10px;
			font-size:15px;
			letter-spacing:-0.05em;
		}
		& .btn {
			button {
				padding:15px 5px;
				font-size:13px;
			}
		}
		.card_item {
			&:after {
				font-size:12px;
			}
			& .title {
				font-size:15px;
			}
			& .desc {
				font-size:13px;
			}
		}
		& .timeline {
			margin:25px 0;
			.item {
				h3 {
					font-size:16px;
				}
				p {
					font-size:15px;
				}
			}
		}
		.contact_me {
			padding:10px;
		}
		& .social {
			a {
				width:30px;
				height:30px;
			}
		}
	}
	#contact .card, #experience .card {
		& .header {
			height:120px;
			.avatar {
				width:60px;
				height:60px;
				left:20px;
				top:30px;
			}
			.txt_wrap {
				margin-left:80px;
				p {
					padding-top:0;
					font-size:18px;
				}
				span {
					font-size:14px;
					text-align:left;
				}
			}
		}
	}
	.down {display:none;}
}

@media only screen and (max-width:768px) {
	.container {
		&_inner {
			.content_bg{
				border-radius:20px 20px 0 0;
				.main_txt {
					top:5%;
					font-size:30px;
				}
			}
			
		}
	}
	.visual{
		.my_card {
			max-width:450px;
			width:80%;
			transform:translate(0%, 50%) rotate(0);
		}
	}
	.mar {
		p {
			font-size:100px;
		}
	}
}