@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700&family=Poppins:wght@900&family=Roboto:wght@400;500;700&display=swap');
@import './theme.scss';

/* ------------------------------ [ font ] ------------------------------------------------------------------------------------------ */
@font-face {
	font-family:"HyundaiSans";
	font-style:normal;
	font-weight:400;
	src:url("../font/HyundaiSansHeadKROTFRegular.woff") format("woff");
}


/* ------------------------------ [ Layout Reset ] ------------------------------------------------------------------------------------------ */
/* Reset */
* {word-break:keep-all; word-wrap:break-word;}
html,body,div,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,
abbr,address,big,cite,code,del,dfn,font,figure,figcaption,img,ins,q,s,samp,small,strike,sub,sup,tt,var,
b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td {
	color:$black;
	margin:0;
	padding:0;
}
html,body,div,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,
abbr,address,big,cite,code,del,dfn,font,figure,figcaption,img,ins,q,s,samp,small,strike,sub,sup,tt,var,
b,u,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td {font:18px/1.3em 'HyundaiSans', 'Noto Sans KR',sans-serif;}
button,input,textarea {font:18px/1.3em 'HyundaiSans', 'Noto Sans KR',sans-serif;}
select {font:18px/1.3em 'HyundaiSans', 'Noto Sans KR',sans-serif;}
button,input,textarea {
	-webkit-text-size-adjust:none;
	-webkit-appearance:none;
	-webkit-border-radius:0;
	border:none;
	border-radius:0;
	margin:0;
	padding:0;
}
button {
	background:none;
	border:none;
	color:inherit;
	cursor:pointer;
}
button:disabled {
	cursor:default;
}
table {
	border-collapse:collapse;
	border-spacing:0;
	table-layout:fixed;
	width:100%;
}
select {
	background-color:transparent;
	border:none;
	letter-spacing:inherit;
	appearance:none;
	-webkit-appearance:none;
	-moz-appearance:none;
	-webkit-font-smoothing:antialiased;
	border:none;
	border-radius:0;
	margin:0;
	padding:0;
}
select::-ms-expand {
	display:none; /* 화살표 없애기 for IE10, 11 */
}
address,caption,cite,code,dfn,em,strong,th,var {
	font-style:normal;
}
ul,li,ol {
	list-style-type:none;
}
hr {
	display:none;
}
legend,caption {
	overflow:hidden;
	visibility:hidden;
	position:absolute;
	font-size:0;
	line-height:0;
	height:0;
	z-index:-1;
}
a,a:active,input:active,button:active,select:active,label:active,
a:focus,input:focus,button:focus,select:focus,label:focus {
	text-decoration:none;
	outline:none;
}
a,span,em,strong {
	color:inherit;
	font:inherit;
	border:0;
	margin:0;
	padding:0;
	-webkit-font-smoothing:antialiased;
}
a,input,button,select,label {
	-webkit-tap-highlight-color:transparent;
}
::-webkit-input-placeholder {
	color:$placeholder;
	font:inherit;
	line-height:inherit;
}
input:disabled::-webkit-input-placeholder {
	color:$placeholder;
}
:-moz-placeholder {
	color:$placeholder;
	font:inherit;
	line-height:inherit;
}
input:disabled:-moz-placeholder {
	color:$placeholder;
}
::-moz-placeholder {
	color:$placeholder;
	font:inherit;
	line-height:inherit;
}
input:disabled::-moz-placeholder {
	color:$placeholder;
}
:-ms-input-placeholder {
	color:$placeholder;
	font:inherit;
	line-height:inherit;
}
input:disabled:-ms-input-placeholder {
	color:$placeholder;
}
input:disabled {
	background-color:#ECECEC;
	color:$placeholder !important;
}
textarea::placeholder {
	color:$placeholder;
}
textarea::-webkit-placeholder {
	color:$placeholder;
}
textarea::-moz-placeholder {
	color:$placeholder;
}
textarea::-ms-placeholder {
	color:$placeholder;
}
.blind {
	overflow:hidden;
	font-size:0;
	line-height:0;
	position:absolute;
	height:0;
	width:0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow:0 0 0px 1000px #fff inset;
	transition:background-color 5000s ease-in-out 0s;
}
.go_view {position:absolute; left:0; top:0; height:100%; width:100%;}
figure img {display:block; width:100%; height:auto; object-fit:cover;}
a:focus,button:focus,input:focus,select:focus,textarea:focus {
	-webkit-box-shadow: 0 0 0 2px #fff;
	box-shadow: 0 0 0 2px #fff;
	outline: 2px dotted black
}