@import '../theme.scss';

/* ============================== [ Editor ] ========================================================================================== */
.edit {
	padding-top:200px;
	padding-bottom:130px;
	h3 {
		position:relative;
		margin-bottom:30px;
		padding-top:5px;
		font-size:24px;
		font-weight:700;
		letter-spacing:-0.03em;
		color:#fff;
		&:before{
			display:block;
			content:'';
			width:25px;
			height:4px;
			position:absolute;
			left:0; 
			top:-2px;
			background-color:$primary;
		}
	}
	.inpt {
		width:100%;
		height:50px;
		border:1px solid #f1ebe5;
		border-radius:10px;
		box-sizing:border-box;
		padding:0 24px;
		margin-bottom:40px;
		font-size:20px;
		font-weight:500;
		outline-color:transparent;
	}
	.btn_sand {
		display:block;
		width:180px;
		height:40px;
		border:1px solid #f1ebe5;
		border-radius:10px;
		margin-top:30px;
		margin-left:auto;
		font-size:18px;
		font-weight:600;
		line-height:1em;
		transition:.3s;
		cursor:pointer;
		&:hover{
			border-color:$primary;
			background-color:$primary;
			color:#fff;
		}
	}
	.form {
		display:flex;
		gap:20px;
		.layout {
			flex:1;
		}
	}
}

/* option */
.opt {
	display:flex;
	gap:30px;
	.inpt_wrap {
		flex:1;
		p {
			display:flex;
			color:#fff;
			span {
				font-weight:500;
				color:#fff;
			}
			strong {
				margin-left:auto;
				font-weight:700;
				color:#fff;
			}
		}
	}
}

/* textarea */
.txt_area {
	margin-top:30px;
	textarea {
		width:100%;
		height:250px;
		padding:15px;
		box-sizing:border-box;
		background-color:$white;
		border:1px solid #f1ebe5;
		border-radius:10px;
		outline-color:transparent;
		font-weight:500;
		resize:none;
	}
}

/* input [type='range'] */
input[type='range'] {
	-webkit-appearance:none; 
	background:$primary; 
	width:100%; 
	height:4px; 
	outline:none;
}
input[type='range']::-webkit-slider-thumb{
	-webkit-appearance:none; 
	background-color:$primary; 
	border:1px solid #000; 
	width:25px; 
	height:25px; 
	margin-top:-2px; 
	box-shadow:1px 1px 1px $black, 0px 0px 1px $black; border-radius:50%;
	cursor:pointer; 
}

@media only screen and (max-width:768px) {
	.edit {
		.form {
			flex-direction:column;
			gap:10px;
		}
		h3 {
			margin-bottom:10px;
		}
	}
	.opt {
		gap:20px;
		flex-direction:column;
	}
	.txt_area {
		textarea {
			height:220px;
		}
	}
}