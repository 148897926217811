@import '../theme.scss';

.wrap {
	padding-bottom:100px;
	* {
		color:#fff;
	}
}
.close {
	position:fixed;
	right:30px;
	top:30px;
	width:70px;
	height:70px;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color:#fff;
	z-index:10;
	&_inner {
		position:relative;
		width:50px;
		height:50px;
		display:flex;
		align-items:center;
		justify-content:center;
		span {
			font-size:14px;
			font-weight:700;
			opacity:0;
			transition:all .3s ease-in;
		}
		.num_01,
		.num_02 {
			content:'';
			position:absolute;
			left:0;
			width:inherit;
			height:4px;
			background:orangered;
			transition:all .3s ease-in;
		}
		.num_01 {
			top:calc(50% - 2px);
			transform:rotate(45deg);
		}
		.num_02 {
			bottom:calc(50% - 2px);
			transform:rotate(-45deg);
		}
	}
	&:hover{
		.close_inner {
			.num_01,
			.num_02 {
				transform:rotate(0);
			}
			.num_01 {
				top:0;
			}
			.num_02 {
				bottom:0;
			}
		}
		span {
			opacity:1;
		}
	}
}

.intro_img {
	position:relative;
	&::before {
		display:block;
		content:'';
		width:100%;
		height:100%;
		background-color:#000;
		position:absolute;
		left:0;
		top:0;
		opacity:.8;
	}
	figure {
		width:100%;
		height:100vh;
		overflow:hidden;
		img {
			height:100%;
		}
	}

	h2 {
		position:absolute;
		left:50%;
		top:50%;
		width:100%;
		color:rgba($color:#fff, $alpha:0.9);
		letter-spacing:-10px;
		animation:introText 3s;
		animation-fill-mode:forwards;
		opacity:0;
		text-align:center;
		font-size:50px;
		transform:translate(-50%, -50%);
		white-space:nowrap;
		box-sizing:border-box;
	}

	.arrow {
		width:23px;
		position:absolute;
		left:50%;
		bottom:40px;
		transform:translateX(-50%);
		&::before{
			display:block;
			content:'';
			width:3px;
			height:30px;
			background-color:#fff;
			transform:rotate(-45deg);
			left:0;
			top:0;
		}
		&::after{
			display:block;
			content:'';
			width:3px;
			height:30px;
			background-color:#fff;
			transform:rotate(45deg);
			position:absolute;
			right:0;
			top:0;
		}
	}
}

@keyframes introText {
	0%{
		letter-spacing:-10px;
		opacity:0;
	}
	100%{
		letter-spacing:10px;
		opacity:1;
	}
}

.pf_info {
	border-top:20px solid orangered;
	.inner {
		max-width:1000px;
		width:100%;
		padding:100px 20px 0;
		margin:0 auto;
		box-sizing:border-box;
	}
	h3 {
		margin-bottom:40px;
		font-weight:700;
		font-size:30px;
	}
	dl {
		display:flex;
		margin-top:20px;
		overflow:hidden;
		dt {
			width:25%;
			height:38px;
			padding:5px 10px;
			box-sizing:border-box;
			border-left:10px solid orangered;
			font-weight:600;
			font-size:20px;
		}
		dd {
			flex:1;
			padding:5px 0px;
			font-weight:500;
			line-height:1.5em;
		}
		ul{
			li{
				position:relative;
				padding-left:20px;
				font-weight:500;
				line-height:1.5em;
				&::after {
					display:block;
					content:'';
					position:absolute;
					left:0;
					top:11px;
					width:10px;
					height:4px;
					background-color:#fff;
				}
			}
		}
	}
}

.btn {
	position:relative;
	display:flex;
	flex-direction:row;
	margin:40px auto 0;
	width:300px;
	height:45px;
	border:3px solid orangered;
	i {
		content:'';
		width:100%;
		height:100%;
		transition:all .3s cubic-bezier(1,.49,.16,.96);
		background:orangered;
		transform-origin:center left;
		transform:scaleX(0);
	}
	i:nth-child(1) {transition-delay:.02s;}
	i:nth-child(2) {transition-delay:.04s;}
	i:nth-child(3) {transition-delay:.06s;}
	i:nth-child(4) {transition-delay:.08s;}
	i:nth-child(5) {transition-delay:.10s;}
	i:nth-child(6) {transition-delay:.12s;}
	i:nth-child(7) {transition-delay:.14s;}
	i:nth-child(8) {transition-delay:.16s;}
	i:nth-child(9) {transition-delay:.18s;}
	i:nth-child(10) {transition-delay:.2s;}
	span {
		position:absolute;
		width:100%;
		font-size:22px;
		font-weight:700;
		letter-spacing:4px;
		line-height:45px;
		text-align:center;
		text-transform:uppercase;
		color:#fff;
		transition:all .25s cubic-bezier(1,.49,.16,.96);
		z-index:100;
	}
	&:hover{
		i{
			transform:scaleX(1);
		}
		span{
			color:#fff;
			transition-delay:.3s;
		}
	}
}
.pf_sec {
	position:relative;
	margin-top:150px;
	padding:0 20px;
	box-sizing:border-box;

	.slick-list {
		margin-right:-20px;
	}
	.slick-list,
	.slick-track,
	.pf_slide {
		height:auto;
	}
	.frame {
		position:relative;
		margin:0 auto;
		border-radius:20px;
		z-index:1;
		&_img{
			position:relative;
		}
		&_inner {
			position:absolute;
			left:0;
			top:0;
			width:100%;
			box-sizing:border-box;
			.scroll_area {
				height:100%;
				background-color:#fff;
				overflow-y:auto;
				overflow-x:hidden;
				&::-webkit-scrollbar-track {
					background-color:#949494;
				}
				&::-webkit-scrollbar-thumb{
					background-color:#1d1d1d;
					border-radius:20px;
				}
				&::-webkit-scrollbar {
					width:14px;
					height:5px;
					background-color:transparent;
				}
			}
			img {
				width:100%;
				height:auto;
			}
		}
	}
	.frame_pc {
		max-width:900px;
		width:100%;
		height:auto;
		.frame_inner {
			max-height:548px;
			height:57vw;
			padding:38px 36px 47px 38px;
		}
		.slick-slide {
			div{
				max-height:1040px;
			}
		}
	}
	.frame_mo {
		width:415px;
		height:815px;
		.frame_img{
			&::before{
				width:91%; 
				height:71px;
				display:block;
				content:'';
				position:absolute;
				left:50%;
				bottom:17px;
				background-color:#000;
				border-radius:0 0 20% 20%;
				transform:translateX(-50%);
			}
			&::after{
				width:91%; 
				height:49px;
				display:block;
				content:'';
				position:absolute;
				left:50%;
				top:17px;
				background-color:#fff;
				border-radius:20% 20% 0 0;
				transform:translateX(-50%);
				z-index:-1;
			}
		}
		.slick-list,
		.slick-track,
		.pf_slide {
			height:1000px;
		}
		.frame_inner {
			width:89.7%;
			height:693px;
			position:absolute;
			left:50.22%;
			top:66px;
			transform:translateX(-50%);
			overflow:hidden;
		}
	}

	.slick_arrow {
		display:flex;
		justify-content:space-between;
		z-index:1;
		button {
			position:absolute;
			top:40%;
			width:50px;
			height:50px; 
			border-radius:50%;
			background-color:#fff;
			background-position:center;
			background-repeat:no-repeat;
			background-size:24px;
			box-shadow:0 4px 8px rgba($color:#000000, $alpha:0.3);
			text-indent:-9999px;
		}
		.prev {
			background-image:url(../../../public/img/arr_l.png);
			left:-100px;
		}
		.next {
			background-image:url(../../../public/img/arr_r.png);
			right:-100px;
		}
	}
}
@media only screen and (max-width:1200px) {
	.intro_img {
		h2 {
			font-size:40px;
		}
	}
	.pf_sec {
		.slick_arrow {
			button {
				top:35%;
			}
			.prev {
				left:70px;
			}
			.next {
				right:70px;
			}
		}
	}
}
@media only screen and (max-width:900px) {
	.pf_sec {
		.slick_arrow {
			button {
				width:40px;
				height:40px;
			}
		}
		.frame_pc {
			.frame_inner {
				padding:3vw 2.6vw 3.9vw;
			}
			.slick-slide {
				width:85vw;
			}
		}
		.frame_mo {
			width:360px;
			height:715px;
			.frame_inner {
				height:582px;
			}
			.slick-slide {
				width:310px;
			}
			.slick_arrow {
				button {
					top:50%;
					transform:translateY(-50%);
				}
			}
			.slick-list,
			.slick-track,
			.pf_slide {
				height:860px;
			}
			.frame_img {
				&::before {
					bottom:15px;
				}
			}
		}
		.frame_mo ~ .slick_arrow {
			width:450px;
		}
	}
}
@media only screen and (max-width:768px) {
	.intro_img {
		h2 {
			padding:0 20px;
			letter-spacing:0;
			animation:none;
			white-space:wrap;
			font-size:30px;
			opacity:1;
			br {
				display:none;
			}
		}
	}
	.close {
		width:50px;
		height:50px;
		&_inner {
			width:40px;
			height:40px;
			span {
				font-size:12px;
			}
		}
	}

	.pf_sec {
		margin-top:120px;
		.frame_pc {
			.frame_inner {
				padding:3.2vw 3.3vw 3.9vw;
				height:56vw;
			}
			.slick-slide {
				width:85vw;
			}
		}
		.frame_mo {
			width:100%;
			max-width:350px;
			height:715px;
			.frame_inner {
				height:560px;
			}
		}
	}
	.pf_info {
		h3 {
			font-size:28px;
			margin-bottom:30px;
		}
		.inner {
			padding-top:60px;
		}
		dl {
			flex-direction:column;
			margin-top:20px;
			dt {
				width:100%;
				height:25px;
				line-height:1;
				border-width:5px;
				font-size:18px;
			}
			dd {
				padding-left:20px;
				margin-top:5px;
			}
			ul {
				li {
					font-size:16px;
				}
			}
		}
	}
	.btn {
		width:250px;
		height:40px;
		span {
			font-size:20px;
			line-height:40px;
		}
	}
}
@media only screen and (max-width:500px) {
	.pf_sec {
		.frame_pc {
			.frame_inner {
				padding:3.2vw 3.3vw 3.9vw;
				height:53vw;
			}
			.slick-slide {
				width:85vw;
			}
		}
		.slick_arrow {
			.prev {
				left:30px;
			}
			.next {
				right:30px;
			}
		}
	}
}
@media only screen and (max-width:400px) {
	.pf_sec {
		.frame_mo {
			height:auto;
			.frame_inner {
				height:139vw;
			}
		}
	}
}